export enum CredentialStatus {
  review,
  approved,
  rejected,
  incomplete,
  expired,
}

export enum ProfessionalQualificationStatus {
  review = 'review',
  approved = 'approved',
  rejected = 'rejected',
  incomplete = 'incomplete',
  expired = 'expired',
  archived = 'archived',
  uploaded_in_error = 'uploaded_in_error',
  pending = 'pending',
}

export enum travel_email_preferences {
  instant = 'instant',
  daily = 'daily',
  weekly = 'weekly',
}

export enum ProfessionalCredentialsStatusEnum {
  blank,
  review,
  upload_complete,
  rejected,
  incomplete,
  approved,
  need_w2_onboarding,
  missing_required,
  expired,
}

export enum BackgroundCheckReportStatusEnum {
  approved = 'approved',
  review = 'review',
}

export enum JobStatusEnum {
  'active' = 'active',
  'canceled' = 'canceled',
  'completed' = 'completed',
  'unfilled' = 'unfilled',
  'clocked_in' = 'clocked_in',
  'clocked_out' = 'clocked_out',
  'no_show' = 'no_show',
  'booked' = 'booked',
  'late_cancellation' = 'late_cancellation',
  'void' = 'void',
  'disputed' = 'disputed',
  'canceled_with_fee' = 'canceled_with_fee',
  'missed_shift' = 'missed_shift',
  'draft' = 'draft',
  'held_for_dispute_review' = 'held_for_dispute_review',
}

export enum ProfessionalStatusEnum {
  application = 'application',
  active = 'active',
  paused = 'paused',
  paused_requirement_submitted = 'paused_requirement_submitted',
  limited_access = 'limited_access',
  limited_access_reinstatement = 'limited_access_reinstatement',
  deactivated = 'deactivated',
}

export enum ProfessionalAppStatusEnum {
  account_info = 'account_info',
  positions = 'positions',
  licenses_and_certs = 'licenses_and_certs',
  markets = 'markets',
  resume = 'resume',
  q_and_a = 'q_and_a',
  resume_review = 'resume_review',
  phone_screen_pending = 'phone_screen_pending',
  phone_screen_scheduled = 'phone_screen_scheduled',
  approved = 'approved',
  archived = 'archived',
  rejected = 'rejected',
}

export enum ProfessionalArchiveReasonEnum {
  'Certification' = 'Certification',
  'Not enough work experience' = 'Not enough work experience',
  'No work experience in US' = 'No work experience in US',
  'Experience clarification' = 'Experience clarification',
  'Education' = 'Education',
  'New grad' = 'New grad',
  'Waitlisted' = 'Waitlisted',
  'Dormant market' = 'dormant_market',
  'Unavailable market' = 'unavailable_market',
  'Low incrementality market' = 'low_incrementality_market',
  'Other' = 'Other',
}

export enum LicenseKindEnum {
  license,
  certification,
  document,
}

export enum LicenseFieldType {
  image,
  pdf,
  date,
  string,
  boolean,
  integer,
}

export enum LicenseFieldKind {
  front,
  back,
  expiration_date,
  state_id,
  other,
  license_number,
}

enum report_status {
  pending = 'pending',
  clear = 'clear',
  consider = 'consider',
  suspended = 'suspended',
  dispute = 'dispute',
  complete = 'complete',
  canceled = 'canceled',
}

export enum AssignmentRequestStatus {
  applied = 'applied',
  accepted = 'accepted',
  rejected = 'rejected',
  unapplied = 'unapplied',
  offer_sent = 'offer_sent',
}

export enum PermissionGroupAccountTypes {
  administrator = 'administrator',
  agency = 'agency',
  facility = 'facility',
  professional = 'professional',
}

export enum PermissionGroupRoles {
  account_manager = 'account_manager',
  admin_login = 'admin_login',
  admin = 'admin',
  agency_admin = 'agency_admin',
  agency_professional = 'agency_professional',
  agent = 'agent',
  assignment_associate = 'assignment_associate',
  billing_manager = 'billing_manager',
  clinical_ops = 'clinical_ops',
  company_admin = 'company_admin',
  credentialist = 'credentialist',
  facility_manager = 'facility_manager',
  finance_accounting = 'finance_accounting',
  finance_cash_app = 'finance_cash_app',
  finance_collections = 'finance_collections',
  finance_credit = 'finance_credit',
  finance_invoicing = 'finance_invoicing',
  finance_payroll = 'finance_payroll',
  professional_manager = 'professional_manager',
  recruiter_associate = 'recruiter_associate',
  recruiter = 'recruiter',
  saas_admin = 'saas_admin',
  saas_requirements_admin = 'saas_requirements_admin',
  sales_admin = 'sales_admin',
  sales_associate = 'sales_associate',
  sales_recruiter = 'sales_recruiter',
  scheduler = 'scheduler',
  staff = 'staff',
  support_admin = 'support_admin',
  support_agent = 'support_agent',
  view_only = 'view_only',
}

export enum QualificationAttributeFieldTypes {
  date = 'date',
  location = 'location',
  state = 'state',
  external_id = 'external_id',
  pdf = 'pdf',
  string = 'string',
  image_back = 'image_back',
  boolean = 'boolean',
  expiration_date = 'expiration_date',
  json = 'json',
  valid_date = 'valid_date',
  enumerable = 'enumerable',
  integer = 'integer',
  image_front = 'image_front',
  license_number = 'license_number',
  enumerable_multi = 'enumerable_multi',
  text = 'text',
  signed_document = 'signed_document',
  csv = 'csv',
}

enum BCCandidateStatus {
  'created' = 'created',
  'id_required' = 'id_required',
  'driver_license_required' = 'driver_license_required',
  'notice_not_delivered' = 'notice_not_delivered',
  'engaged' = 'engaged',
}
enum BCReportStatus {
  'pending' = 'pending',
  'clear' = 'clear',
  'consider' = 'consider',
  'suspended' = 'suspended',
  'dispute' = 'dispute',
  'complete' = 'complete',
  'canceled' = 'canceled',
}

// Copied from in the rails Medely backend app/models/work_history.rb
enum WorkHistoryEmploymentType {
  'per_diem',
  'full_time',
  'part_time',
  'travel_contract',
  'internship',
}

export enum ItemizedTransactionSourceKinds {
  'financial_transaction' = 'financial_transaction',
  'invoice' = 'invoice',
  'billing_group_balance' = 'billing_group_balance',
}

const enums = {
  professional: {
    credential: {
      status: CredentialStatus,
    },
    background_check: {
      report_status,
    },
  },
  license: {
    kind: LicenseKindEnum,
  },
  credential: {
    status: CredentialStatus,
  },
  assignment_request: {
    status: AssignmentRequestStatus,
  },
  background_check: {
    candidate_status: BCCandidateStatus,
    report_status: BCReportStatus,
  },
  work_history: {
    employment_type: WorkHistoryEmploymentType,
  },
};

export default enums;
